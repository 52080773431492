/**
 * @generated SignedSource<<afc389b91540d103d7915715029ab620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NounResolvedNameFragment$data = {
  readonly address: string;
  readonly name: string | null;
  readonly " $fragmentType": "NounResolvedNameFragment";
};
export type NounResolvedNameFragment$key = {
  readonly " $data"?: NounResolvedNameFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NounResolvedNameFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NounResolvedNameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "ResolvedName",
  "abstractKey": null
};

(node as any).hash = "610eb0c093559dbb3c16d49398698df5";

export default node;
